import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Tab from '../Tab';
import List from './List';
import errorBoundary from '@ifeng-fe/errorBoundary';
const { formatImage } = require('@ifeng-fe/public_method');

const tabOptions = [
    {
        title: '热文日榜',
        value: 'daily',
    },
    {
        title: '热文周榜',
        value: 'weekly',
    },
];

class Assess extends React.PureComponent {
    state = {
        activeKey: 'daily',
    };

    componentDidMount() {}
    /**
     * 渲染组件
     */
    render() {
        const {
            // eslint-disable-next-line react/prop-types
            content: { weeklyList, dailyList },
        } = this.props;
        const { activeKey } = this.state;

        return (
            <div className={styles.ranking_list}>
                <Tab
                    options={tabOptions}
                    size="small"
                    activeKey={activeKey}
                    onChange={value => {
                        this.setState({ activeKey: value });
                    }}
                />
                <List content={activeKey === 'daily' ? dailyList : weeklyList} />
            </div>
        );
    }
}

export default errorBoundary(Assess);
