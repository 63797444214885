/* eslint-disable no-unused-expressions */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import moment from 'moment';
import Tab from '../Tab';
import NewsItem from './NewsItem';
import Pagination from '../Pagination';
import Chip from 'Chip';
import { getDynamicDataList, getSelectedPoolDataList } from '../../../../services/api';
let newestData = [];
const pageSize = 40;

const getBol = num => {
    return num % 5 === 0;
};

/**
 * 定义 topNews 组件
 */
const tabOptions = [
    {
        title: '最新',
        value: 'newest',
        url: 'https://tech.ifeng.com/shanklist/5-217662-/',
    },
    {
        title: '原创',
        value: 'original',
        url: 'https://tech.ifeng.com/shanklist/5-35062-/',
    },
    {
        title: '深度',
        value: 'deep',
        url: 'http://tech.ifeng.com/profound/',
    },
    {
        title: '坏消息',
        value: 'bad',
        url: 'https://tech.ifeng.com/shanklist/5-95009-/',
    },
];
const newsMap = {
    newest: '20002',
    original: '5-35062-',
    deep: '5-35059-',
    bad: '5-95009-',
};

class TopNews extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        activeKey: 'newest',
        current: 1,
        list: [],
        count: 0,
    };
    // eslint-disable-next-line func-names
    getNews = async function(key) {
        const { topNewsEditorFirst } = this.props.content;

        if (key === 'newest') {
            const data = await getSelectedPoolDataList(newsMap[key], 200 + topNewsEditorFirst?.length);

            const newData =
                data?.filter(item => {
                    let bol = true;

                    topNewsEditorFirst?.forEach(it => {
                        if (item?.base62Id === it?.base62Id) {
                            bol = false;
                        }
                    });

                    return bol;
                }) || [];

            this.setState({
                list: [...topNewsEditorFirst, ...(newData || [])]?.slice(0, 200),
            });

            return;
        }

        const data = await getDynamicDataList(newsMap[key], 200);

        this.setState({
            list: data || [],
        });
    };

    componentDidMount() {
        const { activeKey } = this.state;
        const { topNewsEditorFirst } = this.props.content;

        this.getNews(activeKey);
        if (activeKey === 'newest') {
            this.timer = setInterval(() => {
                getSelectedPoolDataList(newsMap[activeKey], 200 + topNewsEditorFirst?.length).then(res => {
                    const updateTime = this.state.list?.[this.props.content?.topNewsEditorFirst?.length || 0]?.newsTime;

                    const count = res?.filter(item => {
                        return moment(item?.newsTime).valueOf() > moment(updateTime).valueOf();
                    });

                    if (count?.length > 0) {
                        newestData = res;
                        this.setState({
                            count: count.length,
                        });
                    } else {
                        newestData = [];
                        this.setState({
                            count: 0,
                        });
                    }
                });
            }, 60000);
        }
    }

    componentWillUnmount() {
        this.timer && clearInterval(this.timer);
    }

    generateNewsContent = content => {
        const { topNewsEditorFirst = [] } = content;
        const { activeKey, current, list } = this.state;
        const start = (current - 1) * 40;
        const result = [];

        if (activeKey === 'newest' && current === 1) {
            result.push(
                <div className={style.line}>
                    <div className={style.left}>
                        <Chip key="key_195944" id="195944" type="recommend" title="科技首页-要闻" groupName="正文">
                            <div>
                                {topNewsEditorFirst?.map((item, index) => (
                                    <div
                                        key={item.id}
                                        className={
                                            getBol(index + 1) ? style[('top_wrap', 'big_bottom')] : style.top_wrap
                                        }>
                                        <NewsItem
                                            content={item}
                                            pcNewsMark={item?.pcNewsMark?.indexOf(1) > -1}
                                            chip={true}
                                        />
                                    </div>
                                ))}
                            </div>
                        </Chip>
                        {list?.slice(topNewsEditorFirst.length, Math.floor(pageSize / 2))?.map((item, index) => (
                            <div
                                key={item.id}
                                className={getBol(index + 1 + topNewsEditorFirst.length) && style['big_bottom']}>
                                <NewsItem content={item} newest={activeKey === 'newest'} />
                            </div>
                        ))}
                    </div>
                    <div className={style.right}>
                        {list?.slice(Math.floor(pageSize / 2), pageSize)?.map((item, index) => (
                            <div key={item.id} className={getBol(index + 1) && style['big_bottom']}>
                                <NewsItem content={item} newest={activeKey === 'newest'} />
                            </div>
                        ))}
                    </div>
                </div>,
            );
        } else {
            result.push(
                <div className={style.line}>
                    <div className={style.left}>
                        {list?.slice(start, start + pageSize / 2)?.map((item, index) => (
                            <div key={item.id} className={getBol(index + 1) && style['big_bottom']}>
                                <NewsItem content={item} newest={activeKey === 'newest'} />
                            </div>
                        ))}
                    </div>
                    <div className={style.right}>
                        {list?.slice(start + pageSize / 2, start + pageSize)?.map((item, index) => (
                            <div key={item.id} className={getBol(index + 1) && style['big_bottom']}>
                                <NewsItem content={item} newest={activeKey === 'newest'} />
                            </div>
                        ))}
                    </div>
                </div>,
            );
        }

        return result;
    };

    handleSyncData = () => {
        const { topNewsEditorFirst } = this.props.content;

        this.setState({
            list: [
                ...topNewsEditorFirst,
                ...(newestData?.filter(item => {
                    let bol = true;

                    topNewsEditorFirst?.forEach(it => {
                        if (item?.base62Id === it?.base62Id) {
                            bol = false;
                        }
                    });

                    return bol;
                }) || []),
            ].slice(0, 200),
            count: 0,
            current: 1,
        });
    };
    /**
     * 渲染组件
     */
    render() {
        const { activeKey, current, list, count } = this.state;
        const { content } = this.props;
        const pageOptions = {
            pageNo: current,
            onChange: pageNo => {
                if (pageNo === 'next') {
                    const url = tabOptions.filter(item => item.value === activeKey)?.[0]?.url;

                    window.open(url);

                    return;
                }
                this.setState({ current: pageNo });
            },
            total: list.length,
            pageSize,
        };

        return (
            <div>
                <Tab
                    options={tabOptions}
                    style={{ marginTop: '1.1428rem' }}
                    activeKey={activeKey}
                    onChange={value => {
                        this.setState({ activeKey: value, current: 1 });
                        this.getNews(value);
                    }}
                />
                {activeKey === 'newest' &&
                    count > 0 && (
                        <div onClick={this.handleSyncData} className={style.tip}>
                            <span className={style.text_tip}>有{count}条文章更新，点击查看</span>
                        </div>
                    )}
                <div className={style.news}>{this.generateNewsContent(content)}</div>
                <Pagination options={pageOptions} />
            </div>
        );
    }
}

export default errorBoundary(TopNews);
