import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Logo from './logo/';
import Navigation from '@ifeng-fe/container_channel/common/client/components/newNav';
import UserInfo from '@ifeng-fe/ui_pc_userInfo';
import Search from '@ifeng-fe/ui_pc_search';

/**
 * 定义 Header 组件
 */
class Header extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        IE78: PropTypes.bool,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content, IE78 } = this.props;

        const logoData = {
            logo: content.logo,
            channelAd: content.channelAd,
        };
        const SearchComp = errorBoundary(Search);
        const topNav = (
            <div className={`${styles.main_nav} clearfix`} key="topNav">
                <Chip id="20002" type="struct" title="导航" groupName="头部" content={content.nav}>
                    <Navigation />
                </Chip>
                <div className={styles.user}>
                    <NoSSR>
                        <UserInfo iconShow option={['register', 'login']} isIE78={IE78} />
                    </NoSSR>
                </div>
            </div>
        );
        const topAd = (
            <div key="topAd" className={styles.ad}>
                <NoSSR onSSR={<div className={styles.box} />}>
                    <Ad content={content.topAd} />
                </NoSSR>
            </div>
        );
        const topSearch = (
            <div className={styles.search} key="topSearch">
                <div className={styles.logo}>
                    <Logo content={logoData} />
                </div>
                <div className={styles.search_btn}>
                    <Chip id="20005" type="struct" title="搜索" groupName="头部" content={content.search}>
                        <SearchComp expand IE78={IE78} defaultIsExpand />
                    </Chip>
                </div>
            </div>
        );

        return [topNav, topAd, topSearch];
    }
}

export default errorBoundary(Header);
