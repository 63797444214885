import { jsonp } from '@ifeng-fe/ui_base';
import { formatImage } from '@ifeng-fe/public_method';

// apiUrl为webpack注入的全局变量
/* eslint-disable */
let apiBaseUrl = apiUrl;
/* eslint-enable */

if (typeof window !== 'undefined' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/api';
}

// 根据自媒体账号 id 获取自媒体账号头像
const getwemediaEAccountImg = async wemediaEAccountId => {
    const data = await jsonp(
        `${apiBaseUrl}/finance/index/getwemediaEAccountImg/${wemediaEAccountId}/getwemediaEAccountImg${wemediaEAccountId}`,
        {
            jsonpCallback: `getwemediaEAccountImg${wemediaEAccountId}`,
            cache: false,
        },
    );

    let result = {};

    if (data.code === 0) {
        result = {
            img: data.data.headImage && data.data.headImage !== '' ? formatImage(data.data.headImage, 50, 50) : '',
            weMediaName: data.data.weMediaName ? data.data.weMediaName : '',
            description: data.data.description ? data.data.description : '',
        };
    }

    return result;
};

// 获取手凤首页动态信息流数据
const getDynamicDataList = async (key, len) => {
    const length = len || 12;
    const addKey = key.replace(/-/g, '').replace(/_/g, '');
    const data = await jsonp(`${apiBaseUrl}/tech/dynamicData/${key}/${length}/getDynamicDataCb${addKey}`, {
        jsonpCallback: `getDynamicDataCb${addKey}`,
        cache: false,
    });

    if (data && data.data && data.data.length > 0) {
        return data.data;
    } else {
        return null;
    }
};

// 获取手凤首页选择池信息流数据
const getSelectedPoolDataList = async (key, len) => {
    const length = len || 12;
    const addKey = key.replace(/-/g, '').replace(/_/g, '');
    const data = await jsonp(`${apiBaseUrl}/tech/selectedPoolData/${key}/${length}/getSelectedPoolDataCb${addKey}`, {
        jsonpCallback: `getSelectedPoolDataCb${addKey}`,
        cache: false,
    });

    if (data && data.data && data.data.length > 0) {
        return data.data;
    } else {
        return null;
    }
};

// 获取文章评论数
// 新时代获取文章评论数
const getCommentCount = async url => {
    return await jsonp('//comment.ifeng.com/get.php', {
        data: {
            job: 4,
            format: 'js',
            callback: 'getAllComment1',
            docurl: url.join('|'),
        },
        jsonCallback: 'getAllComment1',
        cache: false,
    });
};

export { getwemediaEAccountImg, getDynamicDataList, getSelectedPoolDataList, getCommentCount };
