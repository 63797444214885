import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';

const tabOptions = [
    {
        title: '热文日榜',
        value: 'daily',
    },
    {
        title: '热文周榜',
        value: 'weekly',
    },
];

class List extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    }
    componentDidMount() {}
    /**
     * 渲染组件
     */
    render() {
        // eslint-disable-next-line react/prop-types
        const { content } = this.props;

        return (
            <div style={{ width: '100%' }}>
                {content?.map((item, index) => {
                    return (
                        <a className={styles.rank} key={index} href={item.url} target="_blank" title={item.title}>
                            <span className={`${styles[`index_${index + 1}`]} ${styles.index}`}>{index + 1}</span>
                            <span className={styles.title}>{item.title}</span>
                        </a>
                    );
                })}
            </div>
        );
    }
}

export default errorBoundary(List);
