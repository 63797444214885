/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Title extends React.PureComponent {
    componentDidMount() {}
    /**
     * 渲染组件
     */
    render() {
        const { title, more, url } = this.props;

        return (
            <div className={styles.tab_content}>
                <a
                    href={more || url}
                    target="_blank"
                    className={`${styles.tab_item} ${styles.active}`}
                    style={{ marginRight: 0 }}>
                    {title}
                </a>
                {more ? (
                    <a href={more} target="_blank" className={styles.more}>
                        {'更多 >'}
                    </a>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

export default errorBoundary(Title);
