/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import moment from 'moment';

class NewsItem extends React.PureComponent {
    componentDidMount() {}
    formatTime = time => {
        return `${time?.split(' ')?.[0]?.split('-')?.[2]}日`;
    };
    /**
     * 渲染组件
     */
    render() {
        const { content, newest, pcNewsMark, chip = false } = this.props;
        const currentDay =
            moment()
                .startOf('day')
                .valueOf() > moment(content.newsTime).valueOf() &&
            moment(content.newsTime).valueOf() <
                moment()
                    .endOf('day')
                    .valueOf();
        const time = content?.newsTime?.split(' ')?.[1]?.split(':');

        return (
            <div className={chip ? styles.news_top_item : styles.news_item}>
                <a
                    className={
                        (content?.selectedPoolTags?.indexOf('13') > -1 && newest) || pcNewsMark
                            ? `${styles.title} ${styles['title_blod']}`
                            : styles.title
                    }
                    href={content.url}
                    target="_blank"
                    title={content.title}>
                    {content.title}
                </a>
                {content.newsTime ? (
                    <span className={styles.date} title={content.newsTime}>
                        {currentDay ? this.formatTime(content.newsTime) : `${time?.[0]}:${time?.[1]}`}
                    </span>
                ) : (
                    <span className={styles.date} title={content.newsTime}>
                        {new Date()?.getDate()}日
                    </span>
                )}
            </div>
        );
    }
}

export default errorBoundary(NewsItem);
