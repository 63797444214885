/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import icon from '../img/video_icon.png';
import defaultImg from '../img/default.png';
import { getwemediaEAccountImg } from '../../../../services/api';
const { formatImage } = require('@ifeng-fe/public_method');

class VideoItem extends React.PureComponent {
    state = {};
    getMediaInfo = async id => {
        const { setParentState } = this.props;
        const data = await getwemediaEAccountImg(id);

        setParentState({
            [id]: data,
        });
    };
    componentDidMount() {
        this.getMediaInfo(this.props.content.wemediaEAccountId);
    }
    /**
     * 渲染组件
     */
    render() {
        const { content, index, mediaInfo } = this.props;

        return (
            <div className={styles.video_item} style={index === 3 || index === 7 ? { marginRight: 0 } : {}}>
                <a href={content.url} target="_blank" className={styles.video_img}>
                    <img className={styles.play_icon} src={icon} />
                    {content?.posterUrl ? (
                        <img src={formatImage(content.posterUrl, 249, 140)} />
                    ) : (
                        <div>
                            <img src={defaultImg} />
                        </div>
                    )}
                </a>
                <a className={styles.video_title} href={content.url} target="_blank" title={content.title}>
                    {content.title?.length > 30 ? `${content.title?.substring(0, 30)}...` : content.title}
                </a>
                <div className={styles.video_info}>
                    <span className={styles.video_source}>{mediaInfo?.[content.wemediaEAccountId]?.weMediaName}</span>
                    <span className={styles.video_date}>{content.newsTime.split(' ')[0]}</span>
                </div>
            </div>
        );
    }
}

export default errorBoundary(VideoItem);
