import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import style from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import { addEventListener } from '@ifeng-fe/ui_base';
import Ad from '@ifeng-fe/ui_pc_ad';
import Header from './header';
import Navigation from './navigation';
import Slider from './slider/';
import TopNews from './topNews/';
import BottomFooter from './footer';
import Cooperation from './cooperation';
import TechVideo from './techVideo';
import ChannelItem from './channelItem';
import Assess from './assess';
import Eye from './eye';
import RankingList from './rankingList';
import HotEvent from './hotEvent';
import Digital from './digital';
class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();
        this.unHandleClick = addEventListener(document, 'click', this.handleClick);
    }

    componentWillUnmount() {
        this.unHandleClick();
    }
    // 全站渠道标记传递，文章页的任意跳转
    handleClick = e => {
        const tag = this.getATag(e.target);

        if (!tag) return;

        let localHref =
            (tag.attributes['sign-trs-href'] ? tag.attributes['sign-trs-href'].value : '') ||
            (tag.attributes['href'] ? tag.attributes['href'].value : '');

        if (
            localHref &&
            localHref !== 'undefined' &&
            localHref !== '' &&
            localHref !== '#' &&
            localHref.indexOf('javascript:') === -1
        ) {
            localHref = localHref.trim();
            const localSearch = location.search;
            const localHash = location.hash;

            tag.setAttribute('sign-trs-href', localHref);

            let newUrl = '';
            let inWhitelist = false;
            const whitelist = ['//dol.deliver.ifeng.com/'];

            for (let i = 0, len = whitelist.length; i < len; i++) {
                if (localHref.indexOf(whitelist[i]) > -1) {
                    inWhitelist = true;
                }
            }
            // 传递下划线开头的统计
            const curSrc = this.getParam(localSearch, localHash);

            if ((localSearch || localHash) && curSrc && !inWhitelist) {
                if (localHref.indexOf('?') > -1) {
                    newUrl = `${localHref}&${curSrc}`;
                } else {
                    newUrl = `${localHref}?${curSrc}`;
                }

                tag.setAttribute('href', newUrl);
            }
        }
    };

    getATag = tag => {
        if (!tag) {
            return null;
        }

        if (tag.tagName !== 'A') {
            return this.getATag(tag.parentElement);
        } else {
            return tag;
        }
    };

    getSign = (localSearch, ret) => {
        const localSearchArr = localSearch.split('#');

        for (let i = 0; i < localSearchArr.length; i++) {
            const localParam = localSearchArr[i];

            if (localParam.indexOf('_') === -1) continue;

            const localParamArr = localParam.split('?');

            for (let j = 0; j < localParamArr.length; j++) {
                const localParam2 = localParamArr[j];

                if (localParam2.indexOf('_') === -1) continue;

                const localParam2Arr = localParam2.split('&');

                for (let m = 0; m < localParam2Arr.length; m++) {
                    const localParam3 = localParam2Arr[m];

                    if (localParam3.indexOf('_') === -1) continue;

                    const localParam3Arr = localParam3.split('|');

                    for (let k = 0; k < localParam3Arr.length; k++) {
                        const localParam4 = localParam3Arr[k];

                        if (localParam4.indexOf('_') !== 0) continue;

                        if (ret === '') {
                            ret += localParam4;
                        } else {
                            ret = `${ret}&${localParam4}`;
                        }
                    }
                }
            }
        }

        return ret;
    };

    getParam = (localSearch, localHash) => {
        let ret = '';

        if (localSearch.indexOf('_zbs') > -1) {
            ret = this.getSign(localSearch, ret);
        }
        if (localHash.indexOf('_zbs') > -1) {
            ret = this.getSign(localHash, ret);
        }

        return ret;
    };

    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const {
            techVideo,
            science,
            phone,
            internet,
            numerical,
            carTech,
            aiList,
            assess,
            eye,
            eyeTalk,
            dailyList,
            weeklyList,
            hotAd,
            digital,
            advancer,
        } = content;
        const eyeData = {
            eye,
            eyeTalk,
        };
        const rankingList = {
            dailyList,
            weeklyList,
        };
        // 头部
        const headerData = {
            nav: content.nav,
            search: content.search,
            topAd: content.topAd,
            logo: content.logo,
            channelAd: content.channelAd,
        };

        // 导航
        const navigationData = {
            nav: content.navigation,
        };

        // 轮播图：编辑可以干预第一条轮播图，如果推荐位中没有数据则全部自动抓取
        const _slider = content.slider ? [...content.slider] : [];
        const sliderEditor = content.sliderEditor || [];

        if (sliderEditor && sliderEditor.length) {
            if (sliderEditor.length === 1) {
                _slider.splice(0, 0, sliderEditor[0]); // 第一帧
            }
        }
        for (let i = 0; i < _slider.length; i++) {
            if (i === 3 || i === 4) {
                console.log(i, _slider[i].title, _slider[i].title2);
                if (_slider[i].title2) {
                    _slider[i].title = _slider[i].title2;
                }
            }
        }

        const sliderData = {
            slider: _slider.slice(0, 5),
            info: {
                sliderAd: content.sliderAd,
            },
        };

        // 要闻区：编辑可以干预1、2,10条，如果推荐位中没有数据则全部自动抓取
        const topNews = {
            topNewsEditorFirst: content.topNewsEditor || [], // 前两条
            info: {
                topNewsAd: content.topNewsAd, // 广告
            },
        };

        // footer
        const footerData = {
            bottomAd: content.bottomAd,
            footer: content.footer,
        };

        // 底部合作
        const cooperation = content.cooperation;

        return (
            <div className={style.ip_col}>
                <Header content={headerData} IE78={content.IE78} />
                <Navigation content={navigationData} />
                <div className={style.space20} />
                <div className={`${style.col_w1000} clearfix`}>
                    <div className={style.col_left}>
                        {/* 轮播图 */}
                        <Chip
                            key="key_195941"
                            id="195941"
                            type="recommend"
                            title="科技首页-轮播图"
                            groupName="正文"
                            content={sliderEditor}
                            translate="handleSliderListData">
                            <Slider totalContent={sliderData} />
                        </Chip>

                        {/* 要闻区 */}
                        <TopNews content={topNews} />
                        <NoSSR onSSR={<div className={style.ad_banner} />}>
                            <Ad content={content.bannerAd01} />
                        </NoSSR>
                        <TechVideo content={techVideo} />
                        <NoSSR onSSR={<div className={style.ad_banner} />}>
                            <Ad content={content.bannerAd02} />
                        </NoSSR>
                        <div className={style.channel}>
                            <div className={style.channel_left}>
                                <ChannelItem
                                    title="互联网"
                                    content={internet}
                                    ad={content.textAd01}
                                    url="https://tech.ifeng.com/shanklist/5-35050-/"
                                />
                            </div>
                            <div className={style.channel_right}>
                                <ChannelItem
                                    title="人工智能"
                                    content={aiList}
                                    url="https://tech.ifeng.com/shanklist/5-305847-/"
                                />
                            </div>
                        </div>
                        <NoSSR onSSR={<div className={style.ad_banner} />}>
                            <Ad content={content.bannerAd03} />
                        </NoSSR>
                        <div className={style.channel}>
                            <div className={style.channel_left}>
                                <ChannelItem title="数码" content={numerical} url="https://tech.ifeng.com/digi/" />
                            </div>
                            <div className={style.channel_right}>
                                <ChannelItem title="手机" content={phone} url="https://tech.ifeng.com/mobile/" />
                            </div>
                        </div>
                        <NoSSR onSSR={<div className={style.ad_banner} />}>
                            <Ad content={content.bannerAd04} />
                        </NoSSR>
                        <div className={style.channel} style={{ marginBottom: '0.5rem' }}>
                            <div className={style.channel_left}>
                                <ChannelItem title="车科技" content={carTech} url="https://tech.ifeng.com/autotech/" />
                            </div>
                            <div className={style.channel_right}>
                                <ChannelItem
                                    title="科技创新"
                                    content={science}
                                    ad={content.textAd02}
                                    url="https://tech.ifeng.com/shanklist/5-35054-/"
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style.col_right}>
                        <HotEvent hotAd={hotAd} />
                        <Digital content={digital} title="数码精选" url="https://tech.ifeng.com/digi/" />
                        <NoSSR onSSR={<div className={style.ad_box} />}>
                            <Ad content={content.asideAd1} />
                        </NoSSR>
                        <Digital content={advancer} title="前行者" url="https://tech.ifeng.com/qxz/" />
                        <Eye content={eyeData} />
                        <NoSSR onSSR={<div className={style.ad_box} />}>
                            <Ad content={content.asideAd2} />
                        </NoSSR>
                        <Assess content={assess} />
                        <NoSSR onSSR={<div className={style.ad_box} />}>
                            <Ad content={content.asideAd3} />
                        </NoSSR>
                        <RankingList content={rankingList} />
                    </div>
                </div>
                <div className={style.foot}>
                    <div className={style.col_w1000}>
                        <NoSSR>
                            <Chip
                                key="key_193756"
                                id="193756"
                                type="static"
                                title="底部合作链接"
                                groupName="底部合作链接"
                                content={cooperation}>
                                <Cooperation />
                            </Chip>
                        </NoSSR>
                        <BottomFooter content={footerData} />
                    </div>
                </div>
                {/* 底纹 */}
                <NoSSR>
                    <Ad content={content.popunderAd} />
                </NoSSR>
                {/* 浮动广告 */}
                <NoSSR>
                    <Ad content={content.coupletAd} />
                    <Ad content={content.floatAd} />
                </NoSSR>
                <ChipEdit transform={transform} />
            </div>
        );
    }
}

export default Layout;
