/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Title from '../Tab/Title';
import VideoItem from './VideoItem';
import errorBoundary from '@ifeng-fe/errorBoundary';

class TechVideo extends React.PureComponent {
    state = {};
    componentDidMount() {}
    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        return (
            <div className={styles.tech_wrap}>
                <Title title="科技视频" more="https://v.ifeng.com/shanklist/v/27-95022-" />
                <div className={styles.techVideo}>
                    {content.map((item, index) => {
                        return (
                            <VideoItem
                                content={item}
                                key={index}
                                index={index}
                                mediaInfo={this.state}
                                setParentState={this.setState.bind(this)}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default errorBoundary(TechVideo);
