import React, { useEffect, useState } from 'react';
import Title from '../Tab/Title';
import ArticleContent from './ArticleContent';
import { getHotEvent } from './service';
import TitleImg from './img/arr_title.png';
import styles from './index.css';
import Chip from 'Chip';
import PropTypes from 'prop-types';

const newsFlowUrl = 'https://ishare.ifeng.com/hotTechRank';

const HotEvent = ({ hotAd }) => {
    const [hotEvent, setHotEvent] = useState([]);

    useEffect(() => {
        getHotEvent(300310, 5).then(res => {
            const list = res?.list || [];

            // eslint-disable-next-line no-unused-expressions
            hotAd && list?.splice(Number(hotAd?.adIndex), 0, hotAd);

            setHotEvent(list?.slice(0, 5) || []);
        });
    }, []);

    return (
        <div className={styles.hotEvent}>
            <Title title="科技热榜" url={newsFlowUrl} />
            <div>
                {hotEvent?.map(item => {
                    const { base62Id, url, title, child, isHotAd } = item;
                    // eslint-disable-next-line react/prop-types
                    const Ele = ({ children, ...params }) =>
                        (isHotAd ? (
                            <Chip
                                key="key_285543"
                                id="285543"
                                type="recommend"
                                title="科技首页-热榜广告位"
                                groupName="科技热榜"
                                content={item}
                                {...params}
                                translate="handleHotAdData">
                                {children}
                            </Chip>
                        ) : (
                            <div {...params}>{children}</div>
                        ));

                    return (
                        <Ele key={base62Id} className={styles.newsflow}>
                            <div>
                                <a href={url}>
                                    <div className={styles.main_title}>
                                        <span title={title}>{title}</span>
                                        <img src={TitleImg} alt="" height={10} />
                                    </div>
                                </a>
                                <ArticleContent child={child} />
                            </div>
                        </Ele>
                    );
                })}
            </div>
            <a href={newsFlowUrl} target="_blank" className={styles.more}>
                查看更多
            </a>
        </div>
    );
};

HotEvent.propTypes = {
    hotAd: PropTypes.object,
};

export default HotEvent;
