/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Pagination extends React.PureComponent {
    componentDidMount() {}

    /**
     * 渲染组件
     */
    render() {
        const {
            options: { pageNo, total, onChange, pageSize },
        } = this.props;
        const pageArr = new Array(Math.ceil(total / pageSize)).fill(1);

        return (
            <div className={styles.pagination}>
                <span
                    className={pageNo === 1 ? styles.button1 : styles.button}
                    onClick={() => {
                        if (pageNo > 1) {
                            onChange(pageNo - 1);
                        }
                    }}>
                    <span style={{ marginRight: '0.3rem' }}>{'<'}</span>
                    上一页
                </span>
                {pageArr.map((item, index) => {
                    return (
                        <span
                            key={index}
                            className={`${styles.page_button} ${pageNo === index + 1 ? styles.active : styles.normal}`}
                            onClick={() => {
                                onChange(index + 1);
                            }}>
                            {index + 1}
                        </span>
                    );
                })}
                <span
                    className={styles.button}
                    style={{ marginRight: 0 }}
                    onClick={() => {
                        if (pageNo < pageArr.length) {
                            onChange(pageNo + 1);
                        } else {
                            onChange('next');
                        }
                    }}>
                    下一页
                    <span style={{ marginLeft: '0.3rem' }}>{'>'}</span>
                </span>
            </div>
        );
    }
}

export default errorBoundary(Pagination);
