import { jsonp } from '@ifeng-fe/ui_base';

export const getHotEvent = async (key, length) => {
    const data = await jsonp(`//shankapi.ifeng.com/feedflow/hotEvents/${key}/0/${length}/callback`, {
        jsonpCallback: 'callback',
        cache: false,
    });

    if (data && data.data) {
        return data.data;
    } else {
        return null;
    }
};
