/* eslint-disable react/prop-types */
import React from 'react';
import styles from './index.css';
import Title from '../Tab/Title';
import RightArticle from './RightArticle';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Digital extends React.PureComponent {
    componentDidMount() {}
    /**
     * 渲染组件
     */
    render() {
        const { content, title, url } = this.props;

        return (
            <div className={styles.digital}>
                <Title title={title} url={url} />
                <RightArticle content={content} url={url} />
            </div>
        );
    }
}

export default errorBoundary(Digital);
