/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Title from '../Tab/Title';
import styles from './index.css';
import NewsItem from '../topNews/NewsItem';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { Event } from '@ifeng-fe/ui_base';
import { handleAd } from '@ifeng-fe/ui_pc_ad';
import defaultImg from '../img/default.png';
const { formatImage } = require('@ifeng-fe/public_method');

const formatList = (list, ads) => {
    for (const ad of ads) {
        if (ad.type === 'replaceArr') {
            list.splice(ad.index, 1, { type: 'ad', ref: ad.ref });
        } else if (ad.type === 'insertArr') {
            list.splice(ad.index, 0, { type: 'ad', ref: ad.ref });
        }
    }

    return list;
};

class ChannelItem extends React.PureComponent {
    state = {
        ads: [],
    };

    event = new Event();

    insert = (insertArr, replaceArr, adFn) => {
        if (adFn && typeof adFn === 'function') {
            const refs = [];

            replaceArr.forEach(index => {
                const ref = React.createRef();

                refs.push({ index, ref, type: 'replaceArr' });
            });

            insertArr.forEach(index => {
                const ref = React.createRef();

                refs.push({ index, ref, type: 'insertArr' });
            });

            this.setState(
                {
                    ads: [...this.state.ads, ...refs],
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        if (ref.ref?.current) {
                            dom[ref.index] = ref.ref.current;
                        }
                    }
                    adFn(dom);
                },
            );
        }

        return;
    };

    async componentDidMount() {
        const { ad } = this.props;

        if (ad) {
            try {
                const callback = await handleAd(ad);

                callback(ad.data, this.event, this.insert);
            } catch (e) {
                console.error(e);
            }

            try {
                this.event.trigger('init');
            } catch (e) {
                console.error(e);
            }
        }
    }

    /**
     * 渲染组件
     */
    render() {
        const { ads } = this.state;
        const { content, title, url } = this.props;
        let data = [...content];

        data = formatList(data, ads);
        const imgUrl = content?.[0]?.thumbnails?.image?.[0]?.url;

        return (
            <div className={styles.channel_item}>
                <Title title={title} more={url} />
                <div
                    className={styles.firat_channel_item}
                    onClick={() => {
                        window.open(content?.[0]?.url);
                    }}>
                    <span className={imgUrl ? styles.img : styles.default_img} target="_blank">
                        {imgUrl ? <img src={formatImage(imgUrl, 198, 110)} /> : <img src={defaultImg} />}
                    </span>
                    <div className={styles.article_info}>
                        <span className={styles.title} target="_blank" title={content?.[0]?.title}>
                            {content?.[0]?.title}
                        </span>
                        <span
                            onClick={e => {
                                if (e && e.stopPropagation) {
                                    e.stopPropagation();
                                } else {
                                    window.event.cancelBubble = true;
                                }
                            }}
                            title={content?.[0]?.newsTime}
                            className={styles.date}>
                            {content?.[0]?.newsTime?.split(' ')?.[0]}
                        </span>
                    </div>
                </div>
                {data?.splice(1, 9)?.map((item, index) => {
                    if (item.type === 'ad') {
                        return <li key={index} ref={item.ref} />;
                    }

                    return <NewsItem key={index} content={item} />;
                })}
            </div>
        );
    }
}

export default errorBoundary(ChannelItem);
