import React from 'react';
import defaultImg from '../img/default.png';
import styles from './index.css';
import PropTypes from 'prop-types';

const ArticleContent = ({ child }) => {
    const { url, title, thumbnail } = child;

    const content = (
        <a className={styles.content} href={url} target="_blank">
            <div className={thumbnail ? styles.img_content : styles.img_content1}>
                <img src={thumbnail || defaultImg} alt="" />{' '}
            </div>
            <div className={styles.text_content} title={title}>
                {title?.length > 28 ? `${title?.substr(0, 28)}...` : title}
            </div>
        </a>
    );

    return content;
};

ArticleContent.propTypes = {
    child: PropTypes.object,
};

export default ArticleContent;
