/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Tab extends React.PureComponent {
    componentDidMount() {}
    /**
     * 渲染组件
     */
    render() {
        const { options, activeKey, onChange, style, size = 'normal' } = this.props;

        return (
            <div className={styles.tab_content} style={style || {}}>
                {options.map((item, index) => {
                    return (
                        <span
                            className={`${styles.tab_item} ${styles[`tab_item_${size}`]} ${
                                item.value === activeKey ? styles.active : styles.normal
                            }`}
                            style={index === options.length - 1 ? { marginRight: 0 } : {}}
                            key={item.value}
                            onClick={() => {
                                onChange(item.value);
                            }}>
                            {item.title}
                        </span>
                    );
                })}
            </div>
        );
    }
}

export default errorBoundary(Tab);
