/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Tab from '../Tab';
import RightArticle from '../assess/RightArticle';
import errorBoundary from '@ifeng-fe/errorBoundary';
const { formatImage } = require('@ifeng-fe/public_method');

const tabOptions = [
    {
        title: '新视界',
        value: 'eye',
    },
    {
        title: '新视界对话',
        value: 'eyeTalk',
    },
];

class Assess extends React.PureComponent {
    state = {
        activeKey: 'eye',
    };

    componentDidMount() {}
    /**
     * 渲染组件
     */
    render() {
        const {
            content: { eye, eyeTalk },
        } = this.props;
        const { activeKey } = this.state;
        const url =
            activeKey === 'eye' ? 'https://tech.ifeng.com/core/' : 'https://tech.ifeng.com/shanklist/5-35057-245791-';

        return (
            <div className={styles.eye}>
                <Tab
                    options={tabOptions}
                    activeKey={activeKey}
                    size="small"
                    onChange={value => {
                        if (value === activeKey) {
                            window.open(url);
                        }
                        this.setState({ activeKey: value });
                    }}
                />
                <RightArticle content={activeKey === 'eye' ? eye : eyeTalk} url={url} />
            </div>
        );
    }
}

export default errorBoundary(Assess);
